import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Policy from "../../components/Policy";

export default function Tandc() {
  const query = graphql`
    query {
      strapiTermsAndConditions {
        Policy {
          PolicyContent
          PolicyHeading
        }
        Seo {
          metaDescription
          metaTitle
          shareImage {
            localFile {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
        }
      }
    }
  `;
  const data = useStaticQuery(query);
  const policy = data.strapiTermsAndConditions.Policy;
  return (
    <>
      <Policy
        seo={data.strapiTermsAndConditions.Seo}
        heading={policy.PolicyHeading}
        content={policy.PolicyContent}
      />
    </>
  );
};
